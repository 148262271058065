import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { useFlexSearch } from 'react-use-flexsearch';
import { graphql, Link, navigate } from 'gatsby';

import linkResolver from '../utils/linkResolver';
import QueryInput from '../components/Header/Search/QueryInput';
import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import MainCol from '../components/PageBody/MainCol';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import chevronDown from '../images/arrows/chevron-down.svg';
import Pagination from '../components/NewsComponents/Pagination';
import { trimTeaser } from '../utils/functions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 36px 208px;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin: 24px;
  }
`;

const QueryForm = styled.form`
  display: flex;
`;

const SubmitButton = styled.button`
  border: none;
  border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
  background: none;
  padding: 0 12px 8px;

  &:hover {
    background-color: ${prop('theme.colors.backgroundGreyLightest')};
  }

  img {
    transform: rotate(-90deg);
    height: 16px;
    width: 16px;

    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      height: 24px;
      width: 24px;
    }
  }
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 16px 0 24px;
`;

const Result = styled.div`
  width: 100%;
  margin: 12px 0;
`;

const ResultTitle = styled(Link)`
  color: ${prop('theme.colors.black')};
  font-size: 1.25rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin-bottom: 8px;

  &:hover {
    color: ${prop('theme.colors.redPrimary')};
    text-decoration: none;
  }
`;

const propTypes = { location: PropTypes.object, data: PropTypes.object };

const defaultProps = { location: {}, data: {} };

function AllSearchResults({ location, data }) {
  const params = new URLSearchParams(location.search);
  const initialQuery = params.get('query');
  const currentPage = parseInt(params.get('page') || '1');
  const [query, setQuery] = useState(location.search ? initialQuery : '');
  const { index, store } = data.localSearchItems;
  const results = useFlexSearch(initialQuery, index, store);
  const numberOfPages = Math.ceil(results.length / 10);

  useEffect(() => {
    setQuery(initialQuery || '');
  }, [initialQuery]);

  return (
    <Layout displaySearchBar={false}>
      <PageContainer>
        <MainCol>
          <ColumnWrapper subNav={false}>
            <Wrapper>
              <QueryForm
                onSubmit={(event) => {
                  event.preventDefault();
                  navigate(`/search/?query=${query}`);
                }}
              >
                <QueryInput query={query} setQuery={setQuery} width={'100%'} />
                <SubmitButton>
                  <img src={chevronDown} />
                </SubmitButton>
              </QueryForm>
              <Results>
                {results
                  .slice((currentPage - 1) * 10, currentPage * 10)
                  .map((result) => (
                    <Result key={result.uid}>
                      <ResultTitle to={linkResolver(result)}>
                        {result.title.text}
                      </ResultTitle>
                      <div>{trimTeaser(result.description)}</div>
                    </Result>
                  ))}
              </Results>
              {results.length > 10 && (
                <Pagination
                  currentPage={currentPage}
                  pageSkip={(currentPage - 1) * 10}
                  pageLimit={10}
                  numberOfPages={numberOfPages}
                  pageUid={`/search/?query=${initialQuery}&`}
                  totalItemCount={results.length}
                  linkType={'queryParameter'}
                />
              )}
            </Wrapper>
          </ColumnWrapper>
        </MainCol>
      </PageContainer>
    </Layout>
  );
}

export const allResultsQuery = graphql`
  {
    localSearchItems {
      index
      store
    }
  }
`;

AllSearchResults.propTypes = propTypes;
AllSearchResults.defaultProps = defaultProps;

export default AllSearchResults;
